var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "위험등록부 목록",
            tableId: "riskRegister",
            columns: _vm.gridRisk.columns,
            data: _vm.riskReduce.riskbooks,
            merge: _vm.gridRisk.merge,
            gridHeight: _vm.setheight,
            editable: _vm.editable && !_vm.disabled,
            selection: "multiple",
            rowKey: "ramSignificantRiskRegisterId",
            checkDisableColumn: "riskRegisterFlag",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  _c(_vm.imprComponent, {
                    tag: "component",
                    attrs: {
                      col: col,
                      props: props,
                      inputEditable: false,
                      requestContentsCols: _vm.requestContentsCols,
                      tableKey: "ramKpsrAssessScenarioId",
                      ibmTaskTypeCd: "ITT0000005",
                      ibmTaskUnderTypeCd: "ITTU000005",
                    },
                    on: { imprChange: _vm.imprChange },
                  }),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "삭제", icon: "remove" },
                        on: { btnClicked: _vm.removeRisk },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }